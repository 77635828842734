/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}
/**
   * 两个数字之间的算法
   *
   * @export
   * @param {*} one 第一个数字
   * @param {*} two 第二个数字
   * @param {*} type 加减乘除(加法:add,减法:sub,乘法:mul, 除法:div)
   */
import { Decimal } from 'decimal.js'
export function decimerAlgorithm(one, two, type) {
  var result = ''
  switch (type) {
    case '+':
      result = new Decimal(one).add(new Decimal(two))
      break
    case '-':
      result = new Decimal(one).sub(new Decimal(two))
      break
    case '*':
      result = new Decimal(one).mul(new Decimal(two))
      break
    case '/':
      result = new Decimal(one).div(new Decimal(two))
      break
    default:
      break
  }
  return result
}

/**
   * @param {number} time
   * @param {string} option
   * @returns {string}
   */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
        1 +
        '月' +
        d.getDate() +
        '日' +
        d.getHours() +
        '时' +
        d.getMinutes() +
        '分'
    )
  }
}

/**
 * 秒数转换成时分秒
 * @param {*} t 秒数值
 * @returns 格式化的值
 */
export function secondToTimeStr(t) {
  if (!t) return '00:00'
  if (t < 60) return '00:' + ((i = t) < 10 ? '0' + i : i)
  if (t < 3600) return '' + ((a = parseInt(t / 60)) < 10 ? '0' + a : a) + ':' + ((i = t % 60) < 10 ? '0' + i : i)
  if (t >= 3600) {
    var a; var i; var e = parseInt(t / 3600)
    return (e < 10 ? '0' + e : e) + ':' + ((a = parseInt(t % 3600 / 60)) < 10 ? '0' + a : a) + ':' + ((i = t % 60) < 10 ? '0' + i : i)
  }
}

/**
   * @param {string} url
   * @returns {Object}
   */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
   * @param {string} input value
   * @returns {number} output value
   */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xDC00 && code <= 0xDFFF) i--
  }
  return s
}

/**
   * @param {Array} actual
   * @returns {Array}
   */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
   * @param {Object} json
   * @returns {Array}
   */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
   * @param {string} url
   * @returns {Object}
   */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

/**
   * @param {string} val
   * @returns {string}
   */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/*
   * 根据数组对象属性删除对应项
   * @param {Array} arr - 数组对象
   * @param {String} attr - 属性
   * @param {} value - 属性值
   * @return void
   */
export function removeByValue(arr, attr, value) {
  var index = 0
  for (var i in arr) {
    if (arr[i][attr] === value) {
      index = i
      break
    }
  }
  arr.splice(index, 1)
}

/**
   * Merges two objects, giving the last one precedence
   * @param {Object} target
   * @param {(Object|Array)} source
   * @returns {Object}
   */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
   * @param {HTMLElement} element
   * @param {string} className
   */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
        classString.substr(0, nameIndex) +
        classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
   * @param {string} type
   * @returns {Date}
   */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
   * @param {Function} func
   * @param {number} wait
   * @param {boolean} immediate
   * @return {*}
   */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
   * This is just a simple version of deep copy
   * Has a lot of edge cases bug
   * If you want to use a perfect deep copy, use lodash's _.cloneDeep
   * @param {Object} source
   * @returns {Object}
   */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
   * 使用JSON.Parse方法来进行对象深拷贝
   *
   * @export
   * @param {*} source 来源
   */
export function jsonDeepClone(source) {
  return JSON.parse(JSON.stringify(source))
}

/**
   * @param {Array} arr
   * @returns {Array}
   */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
   * @returns {string}
   */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
   * Check if an element has a class
   * @param {HTMLElement} elm
   * @param {string} cls
   * @returns {boolean}
   */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
   * Add class to element
   * @param {HTMLElement} elm
   * @param {string} cls
   */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
   * Remove class from element
   * @param {HTMLElement} elm
   * @param {string} cls
   */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

/**
   * 生成guid
   */

export function guid() {
  // Generate four random hex digits.
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()).toUpperCase()
}

/**
   * 生成指定范围的随机数
   *
   * @export
   * @param {*} max
   * @param {*} min
   */
export function getRandomNumber(max, min) {
  for (var i = 0; i < 10; i++) {
    var x = Math.floor(Math.random() * (max - min + 1)) + min
    return x
  }
}

/**
   * 关闭当前tagView
   *
   * @export
   * @param {*} view 当前tag视图（路由）
   * @param {*} redirectRouterNmae 重定向路由名字
   */
import store from '@/store'
import router from '@/router'
export function closeCurrentTagView(view, redirectRouterNmae) {
  // 删除当前tabView，路由跳转到最后一个路由上
  store.dispatch('tagsView/delView', view).then(({ visitedViews }) => {
    const latestView = visitedViews.slice(-1)[0]
    if (redirectRouterNmae) {
      router.push({ name: redirectRouterNmae })
    } else if (latestView) {
      router.push(latestView.fullPath)
    } else {
      // now the default is to redirect to the home page if there is no tags-view,
      // you can adjust it according to your needs.
      if (view.name === 'Dashboard') {
        // to reload home page
        router.replace({ path: '/redirect' + view.fullPath })
      } else {
        router.push('/')
      }
    }
  })
}

/**
   * js实现超过长度的字符截取指定长度（中文字符算2个字符），超出部分以...显示
   * @param {*} str 传入字符串
   * @param {*} len 指定长度
   */
export function cutstr(str, len) {
  var newLength = 0
  var newStr = ''
  var chineseRegex = /[^\x00-\xff]/g
  var singleChar = ''
  if (str == null) {
    return str
  }
  var strLength = str.replace(chineseRegex, '**').length
  if (strLength > len) {
    for (var i = 0; i < strLength; i++) {
      singleChar = str.charAt(i).toString()
      if (singleChar.match(chineseRegex) != null) {
        newLength += 2
      } else {
        newLength++
      }
      if (newLength > len) {
        break
      }
      newStr += singleChar
    }

    if (strLength > len) {
      newStr += '...'
    }
  } else {
    newStr = str
  }
  return newStr
}

/**
   * 前端添加数字千位分割
   * @param {*} numberValue 传入数字
   */
export function stateFormat(numberValue) {
  if (numberValue === 0 || numberValue === '0.00') { return 0 }
  if (numberValue) {
    return Number(numberValue)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
        return $1 + ','
      })
      .replace(/\.$/, '')
  }
}

/**
   * 获取指定范围的所有日期列表集合
   *
   * @export
   * @param {*} start 开始时间
   * @param {*} end 结束时间
   * @return {*} 日期集合
   */
export function getDateList(start, end) {
  const dateList = []
  var startDate = new Date(start)
  var endDate = new Date(end)
  dateList.push(start)
  let isTrue = true
  while (isTrue) {
    startDate.setDate(startDate.getDate() + 1)
    if (startDate.getTime() < endDate.getTime()) {
      dateList.push(parseTime(new Date(startDate), '{y}-{m}-{d}'))
    } else {
      isTrue = false
    }
  }
  dateList.push(end)
  return dateList
}

/* 获取时间段内属于星期一(*)的日期们
   * begin: 开始时间
   * end：结束时间
   * weekNum：星期几 {number}
   */
export function getWeek(begin, end, weekNum) {
  var dateArr = []
  var stimeArr = begin.split('-')// =>["2018", "01", "01"]
  var etimeArr = end.split('-')// =>["2018", "01", "30"]

  var stoday = new Date()
  stoday.setUTCFullYear(stimeArr[0], stimeArr[1] - 1, stimeArr[2])
  var etoday = new Date()
  etoday.setUTCFullYear(etimeArr[0], etimeArr[1] - 1, etimeArr[2])

  var unixDb = stoday.getTime()// 开始时间的毫秒数
  var unixDe = etoday.getTime()// 结束时间的毫秒数

  for (var k = unixDb; k <= unixDe;) {
    const needJudgeDate = msToDate(parseInt(k)).withoutTime
    // 不加这个if判断直接push的话就是已知时间段内的所有日期
    /* eslint-disable */
      if (new Date(needJudgeDate).getDay() == weekNum) {
        dateArr.push(needJudgeDate)
      }
      k = k + 24 * 60 * 60 * 1000
    }
    return dateArr
  }
  
  // 根据毫秒数获取日期
  export function msToDate(msec) {
    const datetime = new Date(msec)
    const year = datetime.getFullYear()
    const month = datetime.getMonth()
    const date = datetime.getDate()
    const hour = datetime.getHours()
    const minute = datetime.getMinutes()
    const second = datetime.getSeconds()
  
    const result1 = year +
                '-' +
                ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
                '-' +
                ((date + 1) < 10 ? '0' + date : date) +
                ' ' +
                ((hour + 1) < 10 ? '0' + hour : hour) +
                ':' +
                ((minute + 1) < 10 ? '0' + minute : minute) +
                ':' +
                ((second + 1) < 10 ? '0' + second : second)
  
    const result2 = year +
                '-' +
                ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
                '-' +
                ((date + 1) < 11 ? '0' + date : date)
  
    const result = {
      hasTime: result1,
      withoutTime: result2
    }
  
    return result
  }